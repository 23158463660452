<script setup>
defineProps({
  // src: {
  //   type: String,
  //   required: true
  // },
  type: {
    type: String,
    default: 'video/mp4'
  },
  autoplay: {
    type: Boolean,
    default: false
  }
})

const play = ref(false)
</script>

<template>
  <div class="iframe">
    <div
      v-if="!play"
      class="overlay"
      @click="play = !play"
    >
      <img
        src="https://stilles.rs/wp-content/uploads/2015/10/kuhinjske-ploce.png"
        alt=""
      >
      <vButton>
        <span>VIDEO</span>
        <vIcon
          id="play"
          :size="32"
        />
      </vButton>
      <!-- <svg viewBox="0 0 28.57 20"><path d="M27.973 3.123A3.578 3.578 0 0 0 25.447.597C23.22 0 14.285 0 14.285 0S5.35 0 3.123.597A3.578 3.578 0 0 0 .597 3.123C0 5.35 0 10 0 10s0 4.65.597 6.877a3.578 3.578 0 0 0 2.526 2.526C5.35 20 14.285 20 14.285 20s8.935 0 11.162-.597a3.578 3.578 0 0 0 2.526-2.526C28.57 14.65 28.57 10 28.57 10s-.002-4.65-.597-6.877Z" fill="red"/><path d="M11.425 14.285 18.848 10l-7.423-4.285v8.57Z" fill="#fff"/></svg> -->
    </div>
    <iframe
      v-else
      src="https://www.youtube-nocookie.com/embed/imsaUEuImYk?si=qv5cnxZkzKSP4suU&autoplay=1&mute=1&controls=0&loop=1&playlist=imsaUEuImYk&vq=hd1080"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    />
  </div>
</template>

<style scoped>
.iframe {
  aspect-ratio: 1.77/1;
  position: relative;
  border-radius: 4px;
  & iframe {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  display: grid;
  place-items: center;
  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}
</style>
