<script setup>
const internal = useInternal()
const imageLoaded = ref(true)
const current = ref(0)
const isAutoAdvancing = ref(true)
const autoAdvanceInterval = ref(null)
const speed = ref(5000)
// const router = useRouter()
const config = useRuntimeConfig().public
const data = [
  {
    title: 'Centar proizvodnje pločastog materijala<br> u Srbiji',
    subtitle: 'Izaberi dekor po svom ukusu i životnom stilu, projektanti će učiniti sve da sprovedu tvoju zamisao u realnost.',
    to: '/plocasti-materijali',
    // image: 'https://beta.stilles.rs/cms/wp-content/uploads/2024/09/board.jpg'
    image: config.CMS + '/wp-content/uploads/2024/09/16178758_1857198221184323_2246474972230655117_o.jpg'
    // image: 'https://beta.stilles.rs/cms/wp-content/uploads/2024/09/klisa.jpg',
    // image: 'https://beta.stilles.rs/cms/wp-content/uploads/2024/09/univer-mp.jpg'
  },
  {
    title: 'Uštedi vreme i novac uz našu novu online prodavnicu okova za nameštaj',
    subtitle: 'Sve što ti je potrebno za tvoj projekat pronađi na našem sajtu i naruči sa lakoćom.',
    to: '/okov-za-namestaj',
    image: config.CMS + '/wp-content/uploads/2024/09/testar-2-scaled.jpg'
  },
  {
    title: 'Kuhinjske radne ploče',
    subtitle: 'Izaberi boju i teksturu koja se uklapa sa ostatkom tvoje nove kuhinje',
    to: '/plocasti-materijali/kuhinjske-radne-ploce',
    image: config.CMS + '/wp-content/uploads/2024/09/kuhinjske-ploce.png'
  },
  {
    title: 'Izrada nameštaja',
    subtitle: 'Savremeni koncept opremanja enterijera, stvarajući prostor u kojem se tvoje želje poklapaju sa kreativnošću naših dizajnera',
    to: '/namestaj',
    image: 'https://stilles.rs/wp-content/uploads/2015/08/SL-30x2.jpg'
  },
  {
    title: 'Univer ploče',
    subtitle: 'Inovativnost dezena',
    to: '/plocasti-materijali/univer',
    image: 'https://stilles.rs/wp-content/uploads/2015/08/SL-23x2.jpg'
  },
  {
    title: 'Kant trake',
    subtitle: 'Prodaja i kantovanje pločastog materijala',
    to: '/plocasti-materijali/kant-trake',
    image: 'https://stilles.rs/wp-content/uploads/2015/08/kant-traka.jpg'
  }
]
const currentValue = computed(() => data[current.value])

const handlePrev = () => {
  imageLoaded.value = false
  autoAdvanceInterval.value = current.value = (current.value - 1 + data.length) % data.length
}

const handleNext = () => {
  if (!internal.isOnline) {
    imageLoaded.value = false
    autoAdvanceInterval.value
      = current.value = (current.value + 1) % data.length
  }
  else {
    isAutoAdvancing.value = true
  }
}
const startAutoAdvance = () => {
  setInterval(() => {
    if (isAutoAdvancing.value) {
      imageLoaded.value = false
      handleNext()
    }
  }, speed.value)
}

// const stopAutoAdvance = () => {
//   clearInterval(autoAdvanceInterval.value)
//   autoAdvanceInterval.value = null
// }

const toggleAutoAdvance = () => {
  isAutoAdvancing.value = !isAutoAdvancing.value
  if (isAutoAdvancing.value && !autoAdvanceInterval.value) {
    startAutoAdvance()
  }
}

onMounted(() => startAutoAdvance())
</script>

<template>
  <NuxtLink :to="currentValue.to" class="a">
    <div class="left">
      <div class="left_content">
        <h1 v-html="currentValue.title" />
        <h2 v-text="currentValue.subtitle" />
      </div>
    </div>
    <div class="hero">
      <!-- <vArea :src="config.CMS + '/wp-content/uploads/2024/09/testar-2-scaled.jpg'" /> -->
      <!-- <img
        :src="currentValue.image"
        @load="imageLoaded = true"
      > -->
      <NuxtImg :src="currentValue.image" :modifiers="{ w: 952, h: 512, q: 90 }" @load="imageLoaded = true" />

      <div class="overlay" />
      <div class="hero_controls">
        <div v-if="!internal.isOnline" class="fx">
          <div class="state">
            {{ current + 1 }}/{{ data.length }}
          </div>
          <div class="circle" @click.stop.prevent="toggleAutoAdvance">
            <vSpinner v-if="!imageLoaded" :size="32" />
            <vIcon v-else :id="isAutoAdvancing ? 'pause' : 'play'" />
          </div>
          <div v-if="!isAutoAdvancing" class="circle" @click.stop.prevent="handlePrev">
            <vIcon id="prev" :size="20" />
          </div>
          <div v-if="!isAutoAdvancing" class="circle" @click.stop.prevent="handleNext">
            <vIcon id="next" :size="20" />
          </div>
        </div>
      </div>
    </div>
  </NuxtLInk>
</template>

<style scoped>
.a {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 24px;
  /* padding-bottom: 32px; */
}

.left {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-column: 1 /span 2;
  height: 100%;
  padding-bottom: 24px;
}

.fx {
  display: flex;
  gap: 10px;
  align-items: center;

  & .state {
    padding-right: 16px;
    color: white;
    font-size: 24px;
    font-weight: 800;
    font-variant-numeric: tabular-nums;
  }
}

h1 {
  font-weight: 800;
  font-size: 46px;
  text-wrap: balance;
  line-height: 1;
  padding-bottom: 16px;
  text-wrap: balance;
  /* display: grid;
  grid-template-columns: auto 24px;
  column-gap: 16px; */

}

.more {
  margin-top: 8px;
  color: rgb(var(--color-rgb) / .5);
}

h2 {
  font-size: 24px;
  /* text-wrap: balance; */
  font-weight: 400;
  line-height: 1.3;
  color: rgb(var(--color-rgb) / .5);
}

.hero {
  grid-column: 3 / -1;
  --radius: 4px;
  height: 0;
  min-height: 512px;
  position: relative;
  background-color: rgb(var(--color-rgb) / .03);
  border-radius: var(--radius);
  color: var(--color);

  & img {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }

  &:deep(.spinner) {
    position: absolute;
    top: calc(50% - 23px);
    left: calc(50% - 23px);
    color: rgb(var(--color-rgb) / .12);
    z-index: 1;
  }
}

.left_content {
  position: relative;
  padding-top: 16px;
  flex: 1;
}

.hero_controls {
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: var(--brand-color);
  z-index: 3;
}

.circle {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  background-color: var(--brand-color);
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  color: white;
  position: relative;

  &:deep(.spinner) {
    color: white;
    top: 0;
    left: 0;
  }

  & svg {
    vertical-align: middle;
  }

  &.loading {
    background-color: transparent;
  }
}

.hero_container {
  position: relative;
  max-width: 1488px;
  margin: 0 auto;
  height: 100%;
  padding: 24px;
  z-index: 3;
  display: flex;
  flex-direction: column;

  & .div {
    margin-top: 48px;
    flex: 1;
    max-width: 700px;
    display: flex;
    /* align-items: center; */
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('https://media.istockphoto.com/id/1488288536/photo/silhouette-cherry-blossom-shadow-on-white-background-illustration-isolated-transparent-of.jpg?s=612x612&w=0&k=20&c=iUPsmSFqEG1FOYGKprHZL5VdtfQe-3taapK_k2mMBfw='); */
  z-index: 2;
  /* mix-blend-mode: multiply; */
  background-size: 75%;
  background-repeat: no-repeat;
  background: linear-gradient(-45deg, rgb(0 0 0 / .25) 0%, transparent 100%), 100% no-repeat;
  border-radius: 4px;
}

@media (max-width: 512px) {
  h2 {
    font-size: 21px;
  }

  .a {
    --radius: 4px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    border-radius: 0;
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
    border-radius: 4px;
  }

  .circle {
    --size: 36px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url('https://media.istockphoto.com/id/1488288536/photo/silhouette-cherry-blossom-shadow-on-white-background-illustration-isolated-transparent-of.jpg?s=612x612&w=0&k=20&c=iUPsmSFqEG1FOYGKprHZL5VdtfQe-3taapK_k2mMBfw='); */
    z-index: 2;
    /* mix-blend-mode: multiply; */
    background-size: 75%;
    background-repeat: no-repeat;
    background: linear-gradient(to bottom right, rgb(0 0 0 / .5) 0%, transparent 100%) 100% no-repeat;
    border-radius: 0;
  }

  h1 {
    font-size: 32px;
    color: white;
    line-height: 1.1;
  }

  h2 {
    color: white;
  }

  .left {
    padding-top: 0;
    padding-bottom: 0;
    z-index: 9;
    border-radius: 0;
    height: auto;

  }

  .left_content {
    position: absolute;
    top: 24px;
    left: 24px;
    right: 24px;
    /* min-height: 256px; */
    color: white;
    /* height: 500px; */
    z-index: 2;
    padding: 0 0 24px;
    border-radius: 0;
  }

  .fx {
    & .state {
      color: white;
      font-size: 18px;
    }
  }

  .hero {
    position: relative;
    border-radius: 0;
    min-height: 60vh;

    & img {
      aspect-ratio: 6/19;
      border-radius: 0;
    }
  }

  .hero_controls {
    padding-right: 24px;
    right: -6px;
    bottom: 24px;
    z-index: 4;
  }

  .hero_container {
    & .div {
      align-items: flex-start;
    }
  }

  .more {

    right: 0;
    top: 16px;
  }

  .circle {
    &:deep(.spinner) {
      color: white;
      top: 2px;
      left: 2px;
    }
  }
}
</style>
